div.axisLabels.xaxisLabel,
div.axisLabels.yaxisLabel {
  font-weight: 400 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

td.legendLabel {
  line-height: 1.1;
}

.active-breadcrumb-node p:last-child {
  font-weight: 600 !important;
}

td.legendLabel{
  border-bottom: dashed 1px #428bca;
  color: #337ab7;
}

td.legendLabel:hover{
  border-bottom: dashed 1px #23527c;
  cursor: pointer;
  color: #23527c;
}

#error-dialog-description a,
#rtc-error-line a,
#notification-snackbar a {
  color: #4682C3;
  text-decoration: none;
  cursor: pointer;
}
